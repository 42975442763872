/* this css allows the usage of \n on i18n strings */

#root {
/*  white-space: pre-line;*/
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.2);
}

::-webkit-scrollbar-thumb {
  background: #eb5757;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
