.footerGrey {
  background-image: url("footer-bg.svg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  height: 1700px;
  text-align: center;
  color: #ffffff;
  position: absolute;
  top: 1000;
  right: 0;
  z-index: 0;
}
