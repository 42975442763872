@font-face {
  font-family: "Yaldevi Colombo Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Yaldevi Colombo Regular"),
    url("YaldeviColombo-Regular.woff") format("woff");
}

@font-face {
  font-family: "Yaldevi Colombo Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Yaldevi Colombo Bold"),
    url("YaldeviColombo-Bold.woff") format("woff");
}

@font-face {
  font-family: "Yaldevi Colombo ExtraLight Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Yaldevi Colombo ExtraLight Regular"),
    url("YaldeviColombo-ExtraLight.woff") format("woff");
}

@font-face {
  font-family: "Yaldevi Colombo Light Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Yaldevi Colombo Light Regular"),
    url("YaldeviColombo-Light.woff") format("woff");
}

@font-face {
  font-family: "Yaldevi Colombo Medium Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Yaldevi Colombo Medium Regular"),
    url("YaldeviColombo-Medium.woff") format("woff");
}

@font-face {
  font-family: "Yaldevi Colombo SemiBold Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Yaldevi Colombo SemiBold Regular"),
    url("YaldeviColombo-SemiBold.woff") format("woff");
}
