.headerGrey {
  /*  background-image: url("header-bg.svg");*/
  background-image: url("wlo-bogen.svg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  height: 760px;
  text-align: center;
  color: #ffffff;
  position: absolute;
  top: 0;
  right: 0;
}

@media (min-width: 1024px) {
  .headerGrey {
    height: 715px;
  }
}

@media (min-width: 1441px) {
  .headerGrey {
    height: 787px;
  }
}
