.image-gallery-icon:hover {
  color: #eb5757;
}
.image-gallery-bullet.active,
.image-gallery-bullet:hover {
  background: #eb5757 !important;
}

.image-gallery-thumbnail-image {
  object-fit: cover;
  height: 60px;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid #eb5757;
}
